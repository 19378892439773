<template>
	<div>
		<div>
			<div class="fix l-0 t-0 w100 z-i-10">
				<headertop/>
				<headers @search="changeactive"/>
			</div>
			<div class="h-155"></div>
		</div>
		
		<div class="pwidth goodslist" id="goodslist" v-cloak >
			<div class="goodslist-top">
				<div class="h-35 p-t-10 f-16">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item> 你所在的位置：<a href="/#/index">首页</a></el-breadcrumb-item>
						<el-breadcrumb-item>商品列表</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
		
		
				<div class="selectclass p-0-10 ">
					<div class="b-b-dcd flex start p-t-15 p-b-5 relative " v-if="selectbrandArr&&selectbrandArr.length">
						<em class="c-6 w-90 tr l-h-25">筛选：</em>
						<ul class="w83 flex start warp o-h" >
							<li class="bg-f5 c-6 h-25 l-h-25 cursor p-0-5 m-r-15 m-b-10" v-for="(item,index) in selectbrandArr" :key="index" @click="delselectbrand(item,index)">
								<span>{{item.classname}}：</span>
								<span :class="item.color">{{item.categoryname||item.brandname}}</span>
								<i class="iconfont b2b-fenleixuanzequxiao f-10 m-l-5 "> </i>
							</li>
								
						</ul>
						<div class="w-80 h-26 b-a-dcd l-h-23 tc c-9 absolute r-20 t-15 cursor" @click="selectbrandArr=[]">清空筛选</div>
					</div>
		
					<div class="b-b-dcd flex start p-t-15 p-b-5 relative">
						<em class="c-6 w-90 tr p-r-10">分类：</em>
						<ul class="w83 flex start warp " style="max-height:70px;" :class="Moreshow?'o-y':'o-h'">
							<li class="p-0 m-r-30 m-b-10 cursor w-85 ell" v-for="(item,index) in wcategoriesshow" :key="index" @click="selectclass(item)">
								<el-tooltip class="item" effect="dark" :content="item.categoryname" placement="top" :enterable="false">
								  <el-button  style="margin: 0;padding: 0;background: unset !important;"> <p class="ell c-3">{{item.categoryname}}</p> </el-button>
								</el-tooltip> 
							</li>
							
						</ul>
						<div class="absolute r-20 cursor" @click="More(0)" v-if="wcategoriesshow&&wcategoriesshow.length>36">展开
							<i> <img :src="Moreshow?require( '../../assets/img/down-u.png'):require( '../../assets/img/down.png')" alt=""></i>
						</div>
					</div>
		
					<!-- 二级分类 -->
					<div v-if="wcategorieschilden" class="">
						<ul v-if="wcategorieschilden.length" class="m-b-15 b-b-dcd flex start p-t-10">
							<em class="c-6 w-90 tr p-r-10">子分类：</em>
							<div class="flex start warp o-y " style="max-height:70px;">
								<li class="m-r-30 cursor m-b-10  w-85 ell" v-for="(item,index) in wcategorieschilden" :key="index" @click="selectclasssubCategory(item)"> 
									<el-tooltip class="item" effect="dark" :content="item.categoryname" placement="top" :enterable="false">
									  <el-button style="margin: 0;padding: 0;background: unset !important;"> <p class="ell c-3">{{item.categoryname}}</p></el-button>
									</el-tooltip>
								</li>
							</div>
						</ul>
					</div>
		
					<div class="flex start p-t-15 p-b-5 relative">
						<em class="c-6 w-90 tr p-r-10">品牌：</em>
						<ul class="w83 flex start warp " style="max-height: 95px;" :class="Moreshow1?'o-y':'o-h'">
							<li class="p-0 m-r-30 m-b-10 cursor w-85 ell" v-for="(item,index) in getbrands" :key="index" @click="selectbrand(item)">
								<el-tooltip class="item" effect="dark" :content="item.brandname" placement="top" :enterable="false">
								  <el-button style="margin: 0;padding: 0;background: unset !important;"><p class="ell c-3">{{item.brandname}}</p></el-button>
								</el-tooltip>
							</li>
						</ul>
						<div class="absolute r-20 cursor" @click="More(1)" v-if="getbrands&&getbrands.length>36">展开
							<i> <img :src="Moreshow1?require( '../../assets/img/down-u.png'):require('../../assets/img/down.png')"></i>
						</div>
						
					</div>
				</div>
			</div>
		
			<div class="sort flex">
				<div class="selectactive tc">
					<em>商品列表：</em>
					<div class="l-h-50"><el-checkbox v-model="checked" @change="changeactive" text-color="#333333" activec>仅显示有活动的</el-checkbox></div>
				</div>
				<div class="p-10 c-9" @click="checkedgrid"><i class="el-icon-s-grid f-26"></i></div>
			</div>
		
			<div class=" bg-f tc min-h-290 relative" v-if="getgoods.length==0">
				<div class="w-160 jz h-180">
					<img class="w-160 " src="../../assets/img/sc-1.png">
					<p class="c-9 tc">暂无数据</p>
				</div>
			</div>
		
			<goods :goods="getgoods" :isline="$store.state.app.tenantshoppingmallconfig.goodsvivewtype"></goods>
		
			
		
			<!-- 翻页 -->
			<div class="pwidth pagination tc">
				<div id="Pagination">
					<el-pagination background layout="prev, pager, next" :page-size="pagesize"
						@current-change="currentchange"
						:current-page="pageno" :total="total">
					</el-pagination>
				</div>
			</div>
		
		
		
		</div>
		
		
		<footers/>
	</div>
</template>

<script>
	import headertop from '../../components/headertop.vue';
	import headers from '../../components/header.vue';
	import footers from "../../components/footer.vue"
	var pageno ,openid,showStock,groupnos,allowPlaceAnOrder
	export default{
		components:{footers,headertop,headers},
		data(){
			pageno = sessionStorage.getItem('pageno');
			openid = sessionStorage.getItem('openid');
			showStock = sessionStorage.getItem('showStock');
			allowPlaceAnOrder = sessionStorage.getItem('allowPlaceAnOrder');
			return{
					// -----分页参数-------------------
				visible: false,
				total: 1,
				pagesize:10,
				time: 1,					//定时
				//   --------------------------
				value: 1,
				Moreshow: false,					//更多
				Moreshow1: false,					//更多
				wcategories: [],					//一级的分类列表
				wcategorieschild: [],					//二级的分类列表
				wcategorieschilden: null,					//点击一级分类后显示的子分类
				wcategoriesshow: [],					//当前显示的分类
				selectbrandArr: [],					//选中的分类,
				getgoods: [],					//商品列表
				getbrands: [],					//品牌列表
				pageno: pageno?pageno*1:1,
				pricetype: {'1': '折扣' ,'2' :'立减价格' ,'3' :'秒杀',  '5':'买赠'},
				Operational:'',
				sortactive:0,
				categoryid:'',
				categoryids:'',
				brandids:'',
				query:this.$route.query.query,
				centerDialogVisible:false,
				radio:0,
				TemplateArr:[],
				goodsno:'',	
				showStock:showStock,
				allowPlaceAnOrder:allowPlaceAnOrder,
				checked:false,//仅显示有活动
				
			}
		},
		watch:{
			// $route(){
			// 	this.getgoodslist()
			// }
		},
		
		created() {
			console.log('pageno',pageno)
			document.documentElement.scrollTop=0
			let classname=['分类','子分类','品牌']
			let color=['c-ff3','c-ff8','c-b14ei']
			if (this.$route.query.goodsid) {
				var das = {
					categoryname: this.$route.query.categoryname,
					categoryno: this.$route.query.goodsid,
					id: this.$route.query.id,
					parentid: this.$route.query.parentid,
					classname:classname[this.$route.query.child*1],
					color:color[this.$route.query.child*1]
					
				};
				this.selectbrandArr.push(das);
			}
					
			if(this.$route.query.brandid){
				var brand={
					brandname:this.$route.query.brandname,brandsid:this.$route.query.brandid,
					classname:classname[this.$route.query.child*1],
					color:color[this.$route.query.child*1]
				}
			
				this.selectbrandArr.push(brand);
			}
			
			this.getTenantid(()=> {
				this.pagesize=this.$store.state.app.tenantshoppingmallconfig.goodsvivewtype==1?10:20
				this.getgoodslist(0);
				this.getRichtext()
				this.getbrand();
				if (this.$route.query.child) {
					this.category(); //二级分类
				} else {
					this.getNavs();
				}
			})
			
			var salegroups=sessionStorage.getItem('salegroups')
			if(salegroups&&salegroups!='undefined')groupnos=JSON.parse(sessionStorage.getItem('salegroups'))[0]
			
		},
		
		methods:{
	
			// 限制最小采购值
			onblur: function onblur(item, typs, batchInfoitem) {
				console.log(item); // 有批号
			
				if (typs) {
					this.getgoods.filter(function(list) {
						if (list.id == item.id) {
							for (var i in list.batchInfoList) {
								if (list.batchInfoList[i].batchno == batchInfoitem.batchno) {
									if (item.littleqty != 0) {
										if (parseInt(batchInfoitem['qty']) <= item.littleqty) {
											console.log('batchInfoitem小于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = item.littleqty;
										} else {
											console.log('batchInfoitem大于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = Math.ceil(parseInt(batchInfoitem['qty']) / item.littleqty) * item.littleqty;
										}
									} else {}
								}
							}
						}
					});
				} else {
					// 无批号
					this.getgoods.filter(function(list) {
						if (list.id == item.id) {
							if (parseInt(item['qty']) <= item.littleqty) {
								console.log('小于', parseInt(item['qty']));
								list['qty'] = item.littleqty*1;
							} else {
								console.log('大于', parseInt(item['qty']));
								item.littleqty=item.littleqty==0?1:item.littleqty
								list['qty'] = Math.ceil(parseInt(item['qty']) / item.littleqty*1) * item.littleqty*1;
							}
						}
					});
				}
			
				console.log(this.getgoods);
			},
			
			
			// 是否显示加入购物车
			ishshowcart(qty,stock){
				
				if(allowPlaceAnOrder==0){
					
					if(qty>stock){
						return false
					}else{
						return true
					}
					
				}else{
					return true
				}
				
			},
			
			
			
			// 更多分类显示
			More: function More(type) {
				if (!type) {
					this.Moreshow = !this.Moreshow;
				} else {
					this.Moreshow1 = !this.Moreshow1;
				}
			},
			
			
			
			// 选中的分类查询
			selectclass: function selectclass(item) {
				console.log(item);
				item['classname']='分类'
				item['color']='c-ff3'
				
				var selectbrands = true;
				if (this.selectbrandArr.length < 6) {
					this.selectbrandArr = [item];
					this.wcategorieschilden = item.subCategory;
					this.pageno=1
					this.getgoodslist(0);
				} else {
					this.$message({showClose: true,message: '警告，不能再选啦',type: 'warning'});
				}
			
				this.Moreshow = false;
			},
			
			// 选择子分类
			selectclasssubCategory: function selectclasssubCategory(item) {
				item['classname']='子分类'
				item['color']='c-ff8'
				this.selectbrandArr.push(item);
				this.pageno=1
				this.getgoodslist(0);
			},
			
			// 选中的品牌
			selectbrand: function selectbrand(item) {
				var selectbrandArr = this.selectbrandArr;
				console.log(item);
				var push = true;
				item['classname']='品牌'
				item['color']='c-b14ei'
				if (this.selectbrandArr.length < 6) {
					for (var i in selectbrandArr) {
						if (selectbrandArr[i].brandsid) 
							if (selectbrandArr[i].brandsid == item.brandsid)push = false;
					}
					if (push) {
						this.selectbrandArr.push(item);
						this.Moreshow1 = false;
						this.pageno=1
						this.getgoodslist(0);
					}
				} else {
					this.$message({showClose: true,message: '警告，不能再选啦',type: 'warning'});
				}
			},
			
			
			// 删除已选分类
			delselectbrand: function delselectbrand(delitem,index) {
				var _this = this;
				console.log('wer', delitem);
				this.selectbrandArr.splice(index, 1);
				this.getgoodslist(0);
				// this.getNavs()
				this.wcategorieschilden = null;
			},
			
			// // 页面跳转到详情
			godetail: function godetail(id) {
				sessionStorage.setItem('pageno',this.pageno);
				this.$router.push({path:'/detail',query:{goodsid:id}})
			},
			
			// 排序
			sort: function sort(type) {
				if (this.getgoods.length) {
					this.sortactive = type;
					this.categoryid = this.selectbrandArr[0] ? this.selectbrandArr[0].categorys[0].id : '';
					this.getgoodslist(type);
				} // alert("暂无商品")
			},
			
			
			//获取导航分类列表POST /b2bgoods/category
			getNavs: function getNavs() {
				var self = this;
				var Url = '/b2bgoods/category';
				var data = {
					openid: openid,
					isloadsub: true
				};
				this.requset(Url, data).then(function(data) {
					var data = data.data;
					self.wcategories = data;
					console.log('分类列表', data);
					if (self.query != "categoryif")self.wcategoriesshow = data;
				});
				
			},
			
			
			
			//获取品牌数据列表POST /b2bgoods/brands
			getbrand: function getbrand() {
				var data = {openid: openid,};
				this.requset('/b2bgoods/brands', data).then(data=>{
					var data = data.data;
					for (var j in data) {
						data[j]['brandsid'] = data[j].id;
					}
					this.getbrands = data;
					
				});
					
			
			},
			
			changeactive(){
				this.pageno=1
				this.getgoodslist()
			},
			
			//根据分类id查询商品列表
			getgoodslist: function getgoodslist() {
				var Self = this;
				// var {selectbrandArr}=this
				var categoryids = this.categoryids||''
				var {selectbrandArr,pageno,pagesize,query,brandids} = this; // 获取所选中的分类id
				for (var i in selectbrandArr) {
					if (!selectbrandArr[i].brandsid) {
						categoryids += selectbrandArr[i].id + ',';
					} else {
						brandids += selectbrandArr[i].brandsid + ',';
					}
				}
				
				var categoryid = categoryids.slice(0, -1); 
				var brandid =brandids?brandids.slice(0, -1):''
				var url = '/b2bgoods/querycategorygoods';
				if(this.$route.query.query){
					this.selectbrandArr=[]
					categoryid=''
				}
				console.log('groupnos',groupnos)
				var data = {
					groupnos:groupnos?groupnos.groupno:'',
					categoryid: categoryid,
					brandid: brandid,
					query: this.$route.query.query,
					pageno: pageno||1,
					pagesize,
					isloadbatch: true,
					ispromotesalesid:this.checked?1:''
					// salesmannum:salesmannum,
				};
				
				sessionStorage.removeItem('pageno');
				
				var loading = this.$loading({lock: true,text: '正在加载 请稍后 ...',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
				this.requset(url, data).then(data=>{
					loading.close();
					if (data.status == "success") {
						console.log(data);
						if(!data.data)	return Self.getgoods = [],Self.total =0;
						data.data.filter(function(item) {
							for (var _i in item.batchInfoList) {
								item.batchInfoList[_i]['qty'] = item.littleqty == 0 ? 1 : item.littleqty;
							}
				
							item['qty'] = item.littleqty == 0 ? item.fltp?item.fltp:1 : item.littleqty;
				
							if (!item.batchInfoList) {
								item['batchInfoList'] = [];
							}
							item['goodsqty']=1
						});
						Self.getgoods = data.data;
						Self.total = data.total;
						console.log(Self.getgoods);
						
					}
					document.documentElement.scrollTop=0
				});
					
				
			
			},
			
			
			currentchange: function currentchange(e) {
				console.log(e);
				this.pageno = e;
				this.getgoodslist(0);
			},
			
			
			// 加入购w物车
			addcarorder: function addcarorder(item, batchInfoitem,index,batindex) {
				var showStock = sessionStorage.getItem('showStock');
				console.log(item,batchInfoitem,index,batindex)
				
				var _this2 = this;  //isvisibl当前业务员是否有权限对个商品下单 0为没有权限
				if (!this.time) return; // var batchno=batchInfoitem.batchno=="defaultbno"?'默认批号':batchInfoitem.batchno
				if(item.isvisible==0){this.$message({message: '您暂无权限',type: 'warning'});;return}
				this.time = 0;
				if(batchInfoitem){
					var showststockn = "库存不足";
					if (showStock != 0) showststockn = '目前库存为' + batchInfoitem.stock;
					// debugger
					if (batchInfoitem.qty > batchInfoitem.stock) {
						if(allowPlaceAnOrder==1){
							if (batchInfoitem.batchno == "defaultbno") {
								this.$confirm('您购买的【' + item.goodsname + '】、批号【默认批号】、' + showststockn + '，商品下次到货时间预计为【' + item.arrivalday +
									'】天，是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										customClass:this.color,
										type: 'warning',
										callback:function(action){
											if(action=='confirm'){
												if (batchInfoitem) this.addcar(item, batchInfoitem.batchno, batchInfoitem.qty).then(res=>{this.changestock(index,item.qty,batindex)});
												if (!batchInfoitem) this.addcar(item, '', item.qty).then(res=>{this.changestock(index,item.qty,batindex)});
												_this2.time = 0;
												setTimeout(function() {_this2.time = 1;}, 1000);
											}else{
												_this2.time = 1;
											}
										}	
									})
							} else {
								this.$message.error( showststockn + '，无法加入，请修改采购数量');
							}
						}else{
							this.$message.error( showststockn + '，无法加入，请修改采购数量');
						}
					} else {
						console.log(item.qty,item.totalstock)
						// if((item.qty<=item.totalstock)&&(item.totalstock!=0)){
							if (batchInfoitem) this.addcar(item, batchInfoitem.batchno, batchInfoitem.qty).then(res=>{this.changestock(index,item.qty,batindex)});
							if (!batchInfoitem) this.addcar(item, '', item.qty).then(res=>{this.changestock(index,item.qty,batindex)});
						// }else{
						// 	this.$message.error('当前库存、' + showststockn + '，无法加入，请修改采购数量');
						// }
					
						setTimeout(function() {_this2.time = 1;}, 1000);
					}
				}else{
					// debugger
					if(allowPlaceAnOrder==1){
						this.addcar(item, '', item.qty).then(res=>{
							if(item.totalstock)this.changestock(index,item.qty)
						});
					}else{
						if((item.qty<=item.totalstock)&&(item.totalstock!=0)){
							this.$message.error( showststockn + '，无法加入，请修改采购数量');
						}
					}
				}
				setTimeout(function() {_this2.time = 1;}, 1000);
			},
			
			// 修改商品列表数量
			changestock(index,qty,batindex){
				if(!batindex){
					this.getgoods[index].totalstock=this.getgoods[index].totalstock-qty
				}else{
					this.getgoods[index].batchInfoList[batindex].stock=this.getgoods[index].batchInfoList[batindex].stock-qty
				}
				this.time = 1;

			},
			
			
			// 加入收藏
			addCollection(goodsid, type) {
				var {getgoods}=this
				// if (!this.time) return;
				this.addCollections(goodsid, type).then(res=>{
					for (var i in getgoods) {
						if (getgoods[i].id == goodsid) {
							getgoods[i].isfav = getgoods[i].isfav ? 0 : 1;
							this.getgoods=getgoods
						}
					}
				})
		
			},
			
			
			//子分类 POST /b2bgoods/category
			category: function category() {
				if (this.$route.query.parentid == null) return;
				var Url = '/b2bgoods/category';
				var data = {parentid: this.$route.query.parentid};
				this.requset(Url, data).then((data)=>{
					this.wcategorieschild = data.data;
					this.wcategoriesshow = data.data;
				});
				
			},
			
			
			checkedgrid(){
				let goodsvivewtype=this.$store.state.app.tenantshoppingmallconfig.goodsvivewtype
				this.$store.state.app.tenantshoppingmallconfig.goodsvivewtype=(goodsvivewtype==2?1:2)
				console.log(this.$store.state.app.tenantshoppingmallconfig.goodsvivewtype)
			}
		}
	}
</script>

<style>
	.o-y::-webkit-scrollbar{display: block !important;}
	.el-checkbox__input.is-checked+.el-checkbox__label {color: unset;}
	.o-y::-webkit-scrollbar-track{background: #ccc;}
</style>



